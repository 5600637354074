import { Box, makeStyles, Typography } from "@material-ui/core";
import Image from "next/image";
import { useCallback, useMemo, VFC } from "react";
import { useOurRouter } from "../../../hooks/useOurRouter";
// eslint-disable-next-line no-restricted-imports
import { UserOnboard } from "../../../reclaim-api/client";
import { Link } from "../../Link";
import { MoreOnboardingsGrid, OnboardingsGridItem } from "./MoreOnboardingsGrid";

const useStyles = makeStyles((theme) => ({
  body: {
    alignItems: "center",
    display: "flex",
  },
  moreLinks: {
    padding: theme.spacing(2, 0, 16),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 0, 4),
    },
    "& a": {
      ...theme.typography.subtitle1,
      display: "inline-block",
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(0.5, 1),
    },
    "& a:hover": {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
  },
  subheading: {
    marginBottom: theme.spacing(1),
  },
}));

export enum OnboardingType {
  BufferTime = "BufferTime",
  OneOnOnes = "OneOnOnes",
}

export type DoneStepBuffetProps = {
  onboardings?: UserOnboard;
  hideExploreOnOwnLinks?: boolean;
};

export const DoneStepBuffet: VFC<DoneStepBuffetProps> = ({ onboardings, hideExploreOnOwnLinks }) => {
  const classes = useStyles();
  const router = useOurRouter();

  const items: OnboardingsGridItem[] = useMemo(() => {
    return [
      {
        id: "one-on-ones",
        title: "Smart 1:1s",
        description: "Auto-schedule your one-on-ones",
        href: "/one-on-ones?action=onboard",
        complete: !!onboardings?.smartOneOnOnes,
        image: (
          <Image
            layout="intrinsic"
            width={500}
            height={500}
            src="/img/onboarding/user/welcome-oneonones.svg"
            alt="welcome one-on-ones"
          />
        ),
      },
      {
        id: "buffer",
        title: "Buffer Time",
        description: "Block time between meetings",
        href: "/onboarding/buffer-time",
        complete: !!onboardings?.bufferTime,
        image: (
          <Image
            layout="intrinsic"
            width={500}
            height={500}
            src="/img/onboarding/user/welcome-buffer.svg"
            alt="buffer time"
          />
        ),
      },
      {
        id: "add-on",
        title: "Google Add-on",
        description: "Reclaim right from Google Calendar",
        href: "https://workspace.google.com/marketplace/app/reclaimai/950518663892",
        complete: !!onboardings?.googleAddOn,
        image: (
          <Image
            layout="intrinsic"
            width={500}
            height={500}
            src="/img/onboarding/user/addon.svg"
            alt="add-on graphic"
          />
        ),
      },
      // {
      //   id: "habits",
      //   title: "Habits",
      //   description: "Bring your Habits to life",
      //   href: '/habits',
      //   image: <Image layout="fill" objectFit='contain' src="/img/onboarding/user/welcome-habits.svg" alt="welcome habits"/>
      // },
      // {
      //   id: "tasks",
      //   title: "Tasks",
      //   description: "To-do list, meet calendar",
      //   href: '/tasks',
      //   image: <Image layout="fill" objectFit='contain' src="/img/onboarding/user/welcome-tasks.svg" alt="welcome tasks"/>
      // },
      // {
      //   id: "sync",
      //   title: "Calendar Sync",
      //   description: "No more juggling schedules",
      //   href: '/sync',
      //   image: <Image layout="fill" objectFit='contain' src="/img/onboarding/user/welcome-sync.svg" alt="calendar sync"/>
      // },
      // {
      //   id: "hours",
      //   title: "Scheduling Hours",
      //   description: "Time blocks for work, meetings and personal hours",
      //   href: "/settings/hours",
      //   image: <Image layout="fill" objectFit='contain' src="/img/workinghours-padded.svg" alt="schedule hours"/>
      // },
    ];
  }, [onboardings?.bufferTime, onboardings?.googleAddOn, onboardings?.smartOneOnOnes]);

  const handleItemClick = useCallback(
    (item: OnboardingsGridItem) => {
      if (item.href && item.id !== "add-on") {
        void router.push(item.href);
      }
    },
    [router]
  );

  return (
    <>
      <Box className={classes.body}>
        <MoreOnboardingsGrid items={items} onItemClick={handleItemClick} />
      </Box>
      {!hideExploreOnOwnLinks && (
        <Box className={classes.moreLinks}>
          <Typography className={classes.subheading} variant="h5">
            Or explore on your own:
          </Typography>
          <Link underline="none" href="/habits">
            Habits
          </Link>
          <Link underline="none" href="/tasks">
            Tasks
          </Link>
          <Link underline="none" href="/sync">
            Calendar Sync
          </Link>
          <Link underline="none" href="/settings/hours">
            Scheduling Hours
          </Link>
        </Box>
      )}
    </>
  );
};
