import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { KeyboardArrowRight } from "@material-ui/icons";
import React, { VFC } from "react";
import BadgeCheckSolidSvg from "../../../img/badge-check-solid.svg";
import { Link } from "../../Link";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 780,
  },
  item: {
    alignItems: "center",
    width: 220,
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    textAlign: "center",
    margin: theme.spacing(2.5),
    backgroundColor: "#E4E6E8",
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: "none",
    padding: theme.spacing(2),
    position: "relative",
    color: theme.colors.black,
    transform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colors.primary,
      boxShadow: "0 7px 33px -8px #5267EA",
      color: theme.colors.white,
      transform: "translateY(-3px)",
      "& $linkArrow": {
        color: theme.palette.secondary.main,
        margin: 0,
        opacity: 1,
      },
      "& $imageContainer": {
        transform: "scale(1.05)",
      },
      "& $viewedIcon": {
        color: theme.palette.common.white,
      },
    },
    transition: theme.transitions.create(["background", "boxShadow", "color", "transform"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  imageContainer: {
    maxHeight: 220,
    position: "relative",
    transform: "none",
    width: 240,
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  words: {
    height: "30%",
  },
  title: {
    display: "flex",
    justifyContent: "center",
  },
  linkArrow: {
    color: theme.colors.black,
    marginRight: -24,
    opacity: 0,
    position: "relative",
    transition: theme.transitions.create(["color", "opacity", "margin"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  description: {
    padding: theme.spacing(0, 1),
  },
  viewedIcon: {
    color: theme.palette.common.black,
    height: 20,
    opacity: 0.2,
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: 20,
    zIndex: 1,
  },
}));

export type OnboardingsGridItem = {
  id: string;
  title: string;
  description: string;
  image: JSX.Element;
  href?: string;
  complete?: boolean;
};

export type MoreOnboardingsGridProps = {
  items: OnboardingsGridItem[];
  onItemClick: (item: OnboardingsGridItem) => void;
};

export const MoreOnboardingsGrid: VFC<MoreOnboardingsGridProps> = ({ items, onItemClick }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container justifyContent={"center"} alignItems="stretch">
      {!!items?.length &&
        items.map((item: OnboardingsGridItem) => (
          <Grid
            key={item.id}
            item
            className={classes.item}
            href={item.href}
            onClick={() => onItemClick?.(item)}
            component={Link}
            underline="none"
          >
            <Box className={classes.imageContainer}>{item.image}</Box>
            <Box className={classes.words}>
              <Box className={classes.title}>
                <Typography variant="h4">{item.title}</Typography>
                <KeyboardArrowRight className={classes.linkArrow} />
              </Box>
              <Typography className={classes.description} variant="body1">
                {item.description}
              </Typography>
            </Box>
            {!!item.complete && <BadgeCheckSolidSvg className={classes.viewedIcon} />}
          </Grid>
        ))}
    </Grid>
  );
};
